import "./Footer.css";
import { Link } from "react-router-dom";
import { Facebook, Instagram, WhatsApp, X, YouTube } from "@mui/icons-material";
const Footer = () => {
  return (
    <footer>
      <div className="footer_container">
        <ul>
          <li>
            <a
              href="https://www.instagram.com/tanujpuniainc/?igsh=MWdpNjRvczQxMXM0OQ%3D%3D"
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <Instagram />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/tanujpuniaINC/"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Facebook />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/tanujpuniainc?lang=en"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              <X />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UClnUBwnvgI7B-S0KkZ-SFtg"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              <YouTube />
            </a>
          </li>
        </ul>
        <ul className="links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/issues">Issuse</Link>
          </li>
          <li>
            <Link to="/media">Media</Link>
          </li>
          <li>
            <Link to="/contact ">Contact</Link>
          </li>
        </ul>
        <p>Copyright © 2024 Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
