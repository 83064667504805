import React from "react";
import "./About.css";
import Banner from "../../layout/Banner/Banner";
import banner from "../../../Assest/Images/image 7.png";
import banner1 from "../../../Assest/Images/Rectangle 15.png";
import tanuj from "../../../Assest/Images/DSC07408-min.jpeg";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div className="about">
      <div className="breadcrums">
        <Link to="/">Home</Link> / <p>About</p>
      </div>
      <Banner img={banner} />
      <div className="about_container">
        <div className="about_container_left">
          <p>
            तनुज पुनिया का जन्म 16 जनवरी 1985 को अलीगढ़ में हुआ। वह रिटायर्ड
            आई॰ए॰एस॰ अधिकारी पी॰एल॰पुनिया के पुत्र हैं, जिन्होंने सेवानिवृत्ति
            के बाद कांग्रेस पार्टी में शामिल होकर 2009-2014 के बीच बाराबंकी से
            लोकसभा का चुनाव में भारी मतों से जीत दर्ज की।तभी 2014-2020 के मध्य
            राज्यसभा के सदस्य चुने गए और 2010-2017 के बीच उन्हें राष्ट्रीय
            अनुसूचित जाति आयोग के अध्यक्ष की ज़िम्मेदारी दी गई जिसे उन्होंने
            बखूबी ढंग से निभाई।
          </p>
          <p>
            तनुज पुनिया ने दून स्कूल और आईआईटी रुड़की जैसे उच्च संस्थान से अपनी
            पढ़ाई पूरी की। ग़रीबों, पिछड़ों और वंचित शोषो के हितों के लिए कार्य
            करने का भाव उनके मन में सदा से ही रहा है ।लोगों की मदद वा उनके हितों
            के लिए हमेशा से तत्पर रहे है।इसके फलस्वरूप एक अच्छी तरह से स्थापित
            आजीविका में जाने के लिए पूर्ण रूप से सक्षम होने के बावजूद भी
            उन्होंने 2007 से सामाजिक सेवा के कार्यों में संलग्न होने का फ़ैसला
            किया।
          </p>
        </div>
        <div className="about_container_right">
          <img src={tanuj} alt="banner" />
        </div>
      </div>
      <div className="about_container1" style={{ marginBottom: "10px" }}>
        <p>
          वह उत्तर प्रदेश कांग्रेस समिति के प्रवक्ता और एस॰सी॰ विभाग के जनरल
          सेक्रेटरी बनाए गए। उन्होंने 2017 में ज़ैदपुर विधान सभा सीट से चुनाव
          लड़ा और 80,000 वोट प्राप्त करके दूसरे स्थान पर जगह बनाई।
        </p>
      </div>{" "}
      <div className="about_container1" style={{ marginTop: "10px" }}>
        <p>
          उनका मानना है कि अच्छी सोच रखने वाले युवा लोग ही देश में समानता लाने
          में योगदान कर सकते हैं। सक्रिय राजनीति में आकर वंचितों को न्याय दिलाने
          के लिए कार्य करना उनका लक्ष्य है। देश सेवा में अच्छे कार्यों को करने
          में अपना योगदान देते रहते है, परंतु जन प्रतिनिधि बनने के बाद वह और
          अच्छी तरह से यह कार्य कर पाने की हिम्मत रखते है। जिसके लिए अब तनुज
          पुनिया कांग्रेस पार्टी के टिकट पर उत्तर प्रदेश के बाराबंकी - 53 लोकसभा
          सीट से चुनाव लड़ जनता के बीच उनका आशीर्वाद पाने में कामयाब हो रहे हैं।
        </p>
      </div>
      <Banner img={banner1} />
    </div>
  );
};

export default About;
