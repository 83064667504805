import img1 from "../src/Assest/Images/WhatsApp Image 2024-03-01 at 8.22.19 PM.jpeg";
import img2 from "../src/Assest/Images/WhatsApp Image 2024-03-01 at 8.48.18 PM.jpeg";
import img3 from "../src/Assest/Images/WhatsApp Image 2024-03-01 at 8.47.51 PM.jpeg";
import img4 from "../src/Assest/Images/WhatsApp Image 2024-03-01 at 8.38.43 PM.jpeg";
import img6 from "../src/Assest/Images/WhatsApp Image 2024-03-01 at 8.30.08 PM.jpeg";
import img5 from "../src/Assest/Images/tanujpuniainc_1704463462_3273599246878077696_11151452588.jpeg";
const data = [
  {
    id: "जनपद में जलभराव की समस्या",
    img: img1,
  },
  {
    id: "युवाओं के रोज़गार की बात",
    img: img5,
  },
  {
    id: "बढ़ती मंहगाई की मार झेलती जनता",
    img: img2,
  },
  {
    id: "आमजनमानस के स्वास्थ की चिंता",
    img: img3,
  },
  {
    id: "बढ़ते हुए भ्रष्टाचार से जनता परेशान",
    img: img4,
  },
  {
    id: "महिला सुरक्षा की बात",
    img: img6,
  },
];
export default data;
