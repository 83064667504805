import React, { useEffect, useState } from "react";
import "./Navbar.css";
import logo from "../../../Assest/Images/pngwing 1.png";
import { Facebook, Instagram, WhatsApp, X, YouTube } from "@mui/icons-material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { Link, NavLink } from "react-router-dom";

const Navbar: React.FunctionComponent = () => {
  const [hamburger, setHamburger] = useState(false);
  useEffect(() => {
    console.log(hamburger);
  }, [hamburger]);

  return (
    <nav>
      <div className="container">
        <div className="nav_left">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <span>
            <strong>TANUJ PUNIA</strong>
            <p>53 Lok Sabha, barabanki</p>
          </span>
        </div>
        <span className="hamberger" onClick={() => setHamburger(!hamburger)}>
          {" "}
          <MenuTwoToneIcon />
        </span>
        <div className="nav_right">
          <ul className="nav_right_ul">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/issues"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Issues
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/media"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Media
              </NavLink>
            </li>
          </ul>
          <ul className="nav-icons">
            <li>
              <a
                href="https://www.instagram.com/tanujpuniainc/?igsh=MWdpNjRvczQxMXM0OQ%3D%3D"
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/tanujpuniaINC/"
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <Facebook />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/tanujpuniainc?lang=en"
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <X />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UClnUBwnvgI7B-S0KkZ-SFtg"
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <YouTube />
              </a>
            </li>
          </ul>
        </div>
        {hamburger && (
          <div
            className="mobile-hamburger slide-left"
            onClick={() => setHamburger(!hamburger)}
          >
            <ul className="mobile-nav ">
              <li>
                <NavLink
                  onClick={() => setHamburger(!hamburger)}
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setHamburger(!hamburger)}
                  to="/about"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setHamburger(!hamburger)}
                  to="/issues"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Issues
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setHamburger(!hamburger)}
                  to="/media"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Media
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
