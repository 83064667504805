import React from "react";
import "./Issues.css";
import { Link } from "react-router-dom";
import IssuePageCard from "../../layout/IssuePageCard/IssuePageCard";
import img from "../../../Assest/Images/image 8.png";
import img1 from "../../../Assest/Images/WhatsApp Image 2024-03-01 at 8.22.19 PM.jpeg";
import img2 from "../../../Assest/Images/tanujpuniainc_1704463462_3273599246878077696_11151452588.jpeg";
import img3 from "../../../Assest/Images/WhatsApp Image 2024-03-01 at 8.48.18 PM.jpeg";
import img4 from "../../../Assest/Images/WhatsApp Image 2024-03-01 at 8.47.51 PM.jpeg";
import img5 from "../../../Assest/Images/WhatsApp Image 2024-03-01 at 8.38.43 PM.jpeg";
import img6 from "../../../Assest/Images/WhatsApp Image 2024-03-01 at 8.30.08 PM.jpeg";
const Issues = () => {
  return (
    <div className="issues">
      <div className="breadcrums">
        <Link to="/">Home</Link> / <p>Issues</p>
      </div>
      <div className="issues_cards">
        <IssuePageCard
          img={img1}
          link={"/issues/जनपद में जलभराव की समस्या"}
          title="जनपद में जलभराव की समस्या।"
        />
        <IssuePageCard
          img={img2}
          link={"/issues/युवाओं के रोज़गार की बात"}
          title="युवाओं के रोज़गार की बात ।"
        />
        <IssuePageCard
          img={img3}
          link={"/issues/बढ़ती मंहगाई की मार झेलती जनता"}
          title="बढ़ती मंहगाई की मार झेलती जनता ।"
        />
        <IssuePageCard
          img={img4}
          link={"/issues/आमजनमानस के स्वास्थ की चिंता"}
          title="आमजनमानस के स्वास्थ की चिंता।"
        />
        <IssuePageCard
          img={img5}
          link={"/issues/बढ़ते हुए भ्रष्टाचार से जनता परेशान"}
          title="बढ़ते हुए भ्रष्टाचार से जनता परेशान।"
        />
        <IssuePageCard
          img={img6}
          link={"/issues/महिला सुरक्षा की बात"}
          title="महिला सुरक्षा की बात।"
        />
      </div>
    </div>
  );
};

export default Issues;
