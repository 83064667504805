import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home/Home";
import Navbar from "./components/layout/Navbar/Navbar";
import Footer from "./components/layout/Footer/Footer";
import About from "./components/pages/About/About";
import Issues from "./components/pages/Issues/Issues";
import IssuesSinglePage from "./components/pages/Issues/IssuesSinglePage";
import Contact from "./components/pages/Contact/Contact";
import GoToTop from "./GoToTop";
// import Media from "./components/pages/Media/Media";
const Media = lazy(() => import("./components/pages/Media/Media"));

function App() {
  return (
    <BrowserRouter>
      <GoToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/issues" element={<Issues />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/issues/:singlepage" element={<IssuesSinglePage />} />
        <Route
          path="/media"
          element={
            <Suspense fallback={<p>Loading...</p>}>
              <Media />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
