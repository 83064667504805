import "./Banner.css";
const Banner1 = (props: { img: string }) => {
  return (
    <img
      src={props?.img}
      alt="banner"
      className="banner_img"
      style={{ objectFit: "contain" }}
    />
  );
};

export default Banner1;
