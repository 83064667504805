import React from "react";
import "./OurMissionCard.css";
type ourmissiom_cards_props = {
  logo: string;
  heading: String;
  paragraph: String;
  background?: String;
};
const OurMissionCard = ({
  logo,
  heading,
  paragraph,
  background,
}: ourmissiom_cards_props) => {
  return (
    <div
      className="ourmissioncard"
      style={{ backgroundImage: `url("${background}")` }}
    >
        <img src={logo} alt="logo" />
        <h6>{heading}</h6>
        <p>{paragraph}</p>

    </div>
  );
};

export default OurMissionCard;
