import OurMissionCard from "../OurMissionCard/OurMissionCard";
import "./OurMission.css";
import img from "../../../Assest/Images/pngwing 1.png";
import background from "../../../Assest/Images/image 7.png";

const OurMission = () => {
  return (
    <div className="ourmissiom">
      <h3>
        हमारी प्राथमिकता
        <span>
          <i> हमारा मिशन</i>
        </span>
      </h3>
      <p className="ourmissiom_p_second">
        देश के कानून और व्यवस्था का पालन करने के साथ-साथ सामंजस्यपूर्ण
        सह-अस्तित्व बनाए रखने और राष्ट्र को आगे ले जाने के लिए एक ज़िम्मेदार
        नागरिक के रूप में अपने कर्तव्यों का पालन करने के लिए बाध्य है।
      </p>
      <div className="ourmissiom_cards">
        <OurMissionCard
          logo={img}
          heading={"Economic"}
          paragraph={
            "We are dedicated to promoting sustainable growth, supporting small businesses, and creating jobs."
          }
          background={background}
        />
        <OurMissionCard
          logo={img}
          heading={"Economic"}
          paragraph={
            "We are dedicated to promoting sustainable growth, supporting small businesses, and creating jobs."
          }
          background={background}
        />
        <OurMissionCard
          logo={img}
          heading={"Economic"}
          paragraph={
            "We are dedicated to promoting sustainable growth, supporting small businesses, and creating jobs."
          }
          background={background}
        />
        <OurMissionCard
          logo={img}
          heading={"Economic"}
          paragraph={
            "We are dedicated to promoting sustainable growth, supporting small businesses, and creating jobs."
          }
          background={background}
        />
        <OurMissionCard
          logo={img}
          heading={"Economic"}
          paragraph={
            "We are dedicated to promoting sustainable growth, supporting small businesses, and creating jobs."
          }
          background={background}
        />
        <OurMissionCard
          logo={img}
          heading={"Economic"}
          paragraph={
            "We are dedicated to promoting sustainable growth, supporting small businesses, and creating jobs."
          }
          background={background}
        />
      </div>
    </div>
  );
};

export default OurMission;
