import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import HomeBanner from "../../layout/HomeBanner/HomeBanner";
// import tanuj from "../Home/../../../Assest/Images/";
import tanuj from "../../Home/../../Assest/Images/Rectangle 4.png";
import icon1 from "../../../Assest/Icon/app_registration.png";
import icon2 from "../../../Assest/Icon/local_police.png";
import icon3 from "../../../Assest/Icon/local_police.png";
import icon4 from "../../../Assest/Icon/medication.png";
import icon5 from "../../../Assest/Icon/safety_divider.png";
import OurMission from "../../layout/OurMission/OurMission";

import HomeFlagImg from "../../../Assest/Images/homeFlagImg.png";
import homeSocialMediaImg1 from "../../../Assest/Images/homeSocialMediaImg1.png";
import homeSocialMediaImg2 from "../../../Assest/Images/homeSocialMediaImg2.png";

function Home() {
  return (
    <div className="home">
      <HomeBanner />
      <div className="home_about">
        <div className="home_about_left">
          <h3>TANUJ PUNIA</h3>
          <p>
            तनुज पुनिया का जन्म 16 जनवरी 1985 को अलीगढ़ में हुआ। वह रिटायर्ड
            आई॰ए॰एस॰ अधिकारी पी॰एल॰पुनिया के पुत्र हैं, जिन्होंने सेवानिवृत्ति
            के बाद कांग्रेस पार्टी में शामिल होकर 2009-2014 के बीच बाराबंकी से
            लोकसभा का चुनाव में भारी मतों से जीत दर्ज की।तभी 2014-2020 के मध्य
            राज्यसभा के सदस्य चुने गए और 2010-2017 के बीच उन्हें राष्ट्रीय
            अनुसूचित जाति आयोग के अध्यक्ष की ज़िम्मेदारी दी गई जिसे उन्होंने
            बखूबी ढंग से निभाई।
          </p>
          <p>
            तनुज पुनिया ने दून स्कूल और आईआईटी रुड़की जैसे उच्च संस्थान से अपनी
            पढ़ाई पूरी की। ग़रीबों, पिछड़ों और वंचित शोषो के हितों के लिए कार्य
            करने का भाव उनके मन में सदा से ही रहा है ।लोगों की मदद वा उनके हितों
            के लिए हमेशा से तत्पर रहे है।इसके फलस्वरूप एक अच्छी तरह से स्थापित
            आजीविका में जाने के लिए पूर्ण रूप से सक्षम होने के बावजूद भी
            उन्होंने 2007 से सामाजिक सेवा के कार्यों में संलग्न होने का फ़ैसला
            किया।
          </p>

          <Link to="/about">
            <button className="button">Read More</button>
          </Link>
        </div>
        <div className="home_about_right">
          <div className="home_about_right_img">
            <img src={tanuj} alt="politicion" />
          </div>
        </div>
      </div>
      <div className="home_issues">
        <div className="home_issues_left">
          <p>
            <span></span> The Issues
          </p>
          <h3>
            Our{" "}
            <span>
              <i>Responsibility</i>
            </span>
            <br /> and <i>Government</i> <br /> Accountability
          </h3>
          <Link to="/issues">
            <button>See All Issues</button>
          </Link>
        </div>
        <div className="home_issues_right">
          <ul>
            <li>
              <img src={icon1} alt="icons" />
              <p>Spending and Debt</p>
            </li>
            <li>
              <img src={icon2} alt="icons" />
              <p>Term Limits</p>
            </li>
            <li>
              <img src={icon3} alt="icons" />
              <p>Medicare and Medicaid</p>
            </li>
            <li>
              <img src={icon4} alt="icons" />
              <p>Spending and Debt</p>
            </li>
            <li>
              <img src={icon5} alt="icons" />
              <p>Social Issues</p>
            </li>
          </ul>
        </div>
      </div>
      <OurMission />

      <div className="home_flag">
        <img src={HomeFlagImg} alt="" />
      </div>

      <div className="home_social-media_box">
        <div className="home_social-media">
          <h2>Facebook Updates</h2>
          <img src={homeSocialMediaImg1} alt="" />
        </div>

        <div className="home_social-media">
          <h2>Twitter Updates</h2>
          <img src={homeSocialMediaImg2} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Home;
