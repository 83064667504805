import React from "react";
import "./IssuePageCard.css";
import { Link } from "react-router-dom";

interface IssuePageCardProps {
  img: String;
  link: string;
  title: string;
}
const IssuePageCard = ({ img, link, title }: IssuePageCardProps) => {
  return (
    <div className="issuePageCard" style={{ backgroundImage: `url("${img}")` }}>
      <Link to={link}>
        <p>{title}</p>
      </Link>
    </div>
  );
};

export default IssuePageCard;
