import React, { useEffect, useState } from "react";
import "./Issues.css";
import { Link, useParams } from "react-router-dom";
import Banner1 from "../../layout/Banner/Banner1";
import banner from "../../../Assest/Images/WhatsApp Image 2024-03-01 at 8.22.19 PM.jpeg";
import data from "../../../Issus";

const IssuesSinglePage = () => {
  const [singlePageImage, setSinglePageImage] = useState("");
  const { singlepage } = useParams();
  const getDataHandle = () => {
    const filter = data?.filter((item) => item?.id === singlepage);
    setSinglePageImage(filter && filter[0]?.img);
    console.log(filter, "filter");
  };
  useEffect(() => {
    getDataHandle();
  }, [singlepage]);
  return (
    <div className="issues">
      <div className="breadcrums">
        <Link to="/">Home</Link> / <Link to="/issues">Issues</Link> /{" "}
        <p>{singlepage}</p>
      </div>
      <Banner1 img={singlePageImage} />
      <div className="issues_details">
        <p>
          Once upon a time in the whimsical world of science, there was a group
          of atoms gathered for a grand assembly. They were buzzing with
          excitement, electrons whirling around with joy, as they prepared for
          their annual Science Ball. As the atoms mingled and chatted in the
          great hall, one little hydrogen atom named Harry couldn't help but
          boast about his latest invention. He had just created a new molecule,
          and he was eager to share the news with his fellow atoms. "I've made
          something incredible, my friends!" Harry exclaimed. "It's a molecule
          that's sure to revolutionize the chemistry world!" The other atoms
          gathered around, intrigued by Harry's enthusiasm. "What is it?" asked
          an intrigued oxygen atom. Harry leaned in with a mischievous grin and
          whispered, "It's called 'Sarcasm.' It's a combination of wit and
          irony.
        </p>
        <p>
          the perfect recipe for humor!" The atoms exchanged puzzled looks.
          "Humor?" questioned a skeptical nitrogen atom. "What's that?" Harry
          chuckled. "Well, you see, it's a way to lighten the atmosphere. Just
          like a photon brings light, humor brings joy!" Suddenly, helium,
          always the life of the party, floated into the conversation. "Did
          someone say joy? Count me in! I'm lighter than air, you know!" The
          atoms burst into laughter, their electrons bouncing with amusement.
          Even the stoic carbon atoms cracked a smile, and the hall echoed with
          the sound of their tiny giggles. As the night progressed, Harry's
          molecule, Sarcasm, became the talk of the Science Ball. Soon, every
          atom wanted a taste of this newfound concept. They began crafting
          their own witty remarks and clever jokes, turning the Science Ball
          into the liveliest event in the atomic world. And so, in this
          microscopic realm of science, Harry the hydrogen atom became a legend,
          known for bringing humor to the elemental gatherings. From that day
          forward, the atoms realized that sometimes, laughter is the best bond
          – even stronger than a covalent one. And that, my friends, is the tale
          of Harry Hydrogen and the creation of the legendary molecule, Sarcasm.
          The atoms continued to share jokes and good times, proving that even
          in the vast universe of science, a little humor goes a long way.
        </p>
      </div>
    </div>
  );
};

export default IssuesSinglePage;
