import { Facebook, Instagram, X } from "@mui/icons-material";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact_left">
        <h3>Contact Us</h3>
        <form>
          <div className="form_dual">
            <span>
              <input type="text" placeholder="Full Name" />
            </span>
            <span>
              <input type="text" placeholder="Tehsil Name" />
            </span>
          </div>
          <div className="form_dual">
            <span>
              <input type="text" placeholder="Block Name" />
            </span>
            <span>
              <input type="text" placeholder="Village Name" />
            </span>
          </div>
          <div className="form_dual">
            <span>
              <input type="text" placeholder="Post Code" />
            </span>
            <span>
              <input type="text" placeholder="Mobile Number" />
            </span>
          </div>
          <div className="form_textarea">
            <textarea rows={3} placeholder="Your Message" />
          </div>
          <button className="button" type="submit">
            Contact Us
          </button>
        </form>
      </div>
      <div className="contact_right">
        <span>
          <p className="contact_right_span_p">Contact</p>
          <p className="contact_right_span_p1">test@gmail.com</p>
        </span>
        <span>
          <p className="contact_right_span_p">Bara Banki, Uttar Pradesh</p>
          <p className="contact_right_span_p1">
            {" "}
            Bara Banki, Uttar Pradesh,India
          </p>
        </span>
        <span className="contact_right_icons">
          <Facebook />
          <Instagram />
          <X />
        </span>
      </div>
    </div>
  );
};

export default Contact;
